// React imports

// Remix imports
import type {
  HeadersFunction,
  LoaderFunction,
  MetaFunction,
} from '@remix-run/server-runtime'

// Third party imports

// Generated imports

// App imports
import HomeView, { loaderFunc } from '~/components/views/HomeView'
import { getLang } from '~/i18n.universal'
import getSEOFields from '~/utils/getSEOFields'

// ============================================================================
// HTML =======================================================================
// ============================================================================
export default function LangHomeView() {
  return <HomeView />
}

// ============================================================================
// META =======================================================================
// ============================================================================
export const meta: MetaFunction = ({ data }) => {
  return getSEOFields(data)
}

// ============================================================================
// LOADER =====================================================================
// ============================================================================
export let loader: LoaderFunction = async ({ request, params }) => {
  const lang = getLang(params)
  return loaderFunc(request, params, lang)
}

// ============================================================================
// HEADERS ====================================================================
// ============================================================================
export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    'Cache-Control': 'max-age=0, s-maxage=3600',
  }
}
